import React, { ReactElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DocumentNode } from 'graphql';
import {
  WatchQueryFetchPolicy,
  Context,
  OperationVariables,
  useQuery,
  NetworkStatus,
  FetchMoreQueryOptions,
  ApolloQueryResult,
  ApolloError,
} from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import Button from '@fuww/library/src/Button';
import ReplayIcon from '@fuww/library/src/Icons/Replay';
import ErrorWrapper from '@fuww/library/src/ErrorWrapper';
import Loader from '../components/Loader';
import Error from '../components/Error';
import messages from './messages.mjs';

export const getLoading = (
  clientLoading: boolean,
  setLoadingOnPoll: boolean,
  networkStatus : NetworkStatus,
) => (
  typeof window === 'undefined'
    ? true
    : (!setLoadingOnPoll && networkStatus === NetworkStatus.poll
      ? false : clientLoading)
);

const wrappedComponentPropertyTypes = {
  query: PropTypes.shape({}).isRequired,
  variables: PropTypes.shape({}),
  context: PropTypes.shape({}),
  errorMessage: PropTypes.string.isRequired,
  loader: PropTypes.element,
  showLoaderWhenSetVariables: PropTypes.bool,
  showLoaderWhenDataIsEmpty: PropTypes.bool,
  skip: PropTypes.bool,
  ssr: PropTypes.bool,
  passErrors: PropTypes.arrayOf(PropTypes.string),
  fetchPolicy: PropTypes.string,
  notifyOnNetworkStatusChange: PropTypes.bool,
  showLoader: PropTypes.bool,
};

const withQuery = <
  Q,
  V extends OperationVariables,
  R extends boolean | undefined = false,
  P extends Record<string, unknown> = Record<string, unknown>,
>(
    Component: React.ComponentType<P & {
      data: R extends true ? Q | undefined : Q;
      loading: boolean;
      fetchMore: <
        TFetchData = Q,
        TFetchVariables extends OperationVariables = V,
      >(
        fetchMoreOptions: FetchMoreQueryOptions<TFetchVariables, TFetchData> &
        {
          updateQuery?: ((previousQueryResult: Q, options: {
            fetchMoreResult: TFetchData;
            variables: TFetchVariables;
          }) => Q)
          | undefined;
        }) => Promise<ApolloQueryResult<TFetchData>>;
      variables: V;
      error?: ApolloError;
      refetch: (
        variables?: Partial<V> | undefined
      ) => Promise<ApolloQueryResult<Q>>;
      skip: boolean;
      showLoader: boolean;
    }>,
    {
      renderAlways,
    }: {
      renderAlways?: R;
    } = {},
  ) => {
  const WrappedComponent = ({
    query,
    variables = {} as V,
    errorMessage,
    context = {},
    loader = <Loader />,
    showLoaderWhenSetVariables = true,
    showLoaderWhenDataIsEmpty = false,
    skip = false,
    ssr = true,
    passErrors = [],
    fetchPolicy = 'cache-and-network',
    readyFetchPolicy,
    notifyOnNetworkStatusChange = true,
    pollInterval,
    setLoadingOnPoll = false,
    ...rest
  }: {
    query: DocumentNode;
    variables?: V;
    context?: Context;
    ssr?: boolean;
    passErrors?: string[];
    fetchPolicy?: WatchQueryFetchPolicy;
    readyFetchPolicy?: WatchQueryFetchPolicy;
    notifyOnNetworkStatusChange?: boolean;
    pollInterval?: number;
    setLoadingOnPoll?: boolean;
    showLoader?: boolean;
    skip?: boolean;
    errorMessage: string;
    loader?: ReactElement | null;
    showLoaderWhenSetVariables?: boolean;
    showLoaderWhenDataIsEmpty?: boolean;
    [rest: string]: unknown;
  }) => {
    const [currentFetchPolicy, setCurrentFetchPolicy] = useState(fetchPolicy);
    const {
      loading: clientLoading, error, data, fetchMore, networkStatus, refetch,
    } = useQuery<Q, V>(query, {
      variables,
      context: { ...context, passErrors },
      ssr,
      fetchPolicy: currentFetchPolicy,
      notifyOnNetworkStatusChange,
      pollInterval,
      skip,
    });

    useEffect(() => {
      if (readyFetchPolicy && networkStatus === NetworkStatus.ready) {
        setCurrentFetchPolicy(readyFetchPolicy);
      }
    }, [readyFetchPolicy, networkStatus]);

    const loading = getLoading(clientLoading, setLoadingOnPoll, networkStatus);
    const intl = useIntl();

    const hasError = error
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      && (!error.graphQLErrors || isEmpty(
        error.graphQLErrors
          .filter(({ message }) => passErrors.includes(message)),
      ));

    // if there was a change in the variables (in default case) or
    // it's loading and there is no data yet
    const showLoader = (showLoaderWhenSetVariables
      && networkStatus === NetworkStatus.setVariables)
    || (loading && isEmpty(data))
    || (showLoaderWhenDataIsEmpty && isEmpty(data))
    || skip;

    return (
      <>
        {showLoader && loader}
        {hasError && (
          <ErrorWrapper>
            <Error error={errorMessage} />
            <Button
              startIcon={<ReplayIcon />}
              onClick={() => refetch()}
            >
              {intl.formatMessage(messages.retryLabel)}
            </Button>
          </ErrorWrapper>
        )}
        {((!showLoader && !hasError) || renderAlways || !isEmpty(data)) && (
        <Component
          data={data as typeof renderAlways extends true ? Q | undefined : Q}
          loading={loading}
          fetchMore={fetchMore}
          variables={variables}
          error={error}
          showLoader={showLoader}
          refetch={refetch}
          skip={skip}
          {...rest as P}
        />
        )}
      </>
    );
  };

  WrappedComponent.propTypes = wrappedComponentPropertyTypes;

  return WrappedComponent;
};

export default withQuery;
